export const variables: {
  NODE_ENV: string
  SENTRY_DSN: string
  SENTRY_ENVIRONMENT: string
  DEACTIVATED_FEATURES: string[]
} = {
  NODE_ENV: window.ENV?.NODE_ENV || '',
  SENTRY_DSN: window.ENV?.SENTRY_DSN || '',
  SENTRY_ENVIRONMENT: window.ENV?.SENTRY_ENVIRONMENT || '',
  DEACTIVATED_FEATURES: window.ENV?.DEACTIVATED_FEATURES || [],
}
